import { FC, useEffect, useState } from "react";
import { useDebouncedValue } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import {
  GetFinanceGraphData,
  GetPrescriptionForFinance,
} from "../../utils/query";
import moment from "moment";
import { currencyFormatter } from "../../utils/func";
import { PrescriptionList } from "../../components/prescriptions";
import { FinanceToolBar } from "../../components/Financial";
import FinancialGraph from "../../components/Financial/FinancialGraph";
import { useLoading } from "../../context/LoadingContext"; // Import the loading context
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Authorize } from "../../container";

export const Financial: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<string>("");

  const [debouncedSearchValue] = useDebouncedValue(search, 200);

  const isAuthorized = useSelector(
    (state: RootState) => state.user.isAuthorized
  );

  const { showLoading, hideLoading } = useLoading(); // Use loading context

  const {
    data: getPrescriptionsForFinance,
    isLoading: getPrescriptionsForFinanceLoading,
    refetch: getPrescriptionsForFinanceData,
  } = useQuery({
    queryKey: ["get-prescriptions-for-finance"],
    queryFn: () =>
      GetPrescriptionForFinance({
        page: currentPage,
        limit: 10,
        search: debouncedSearchValue,
        status: sort,
        filter,
      }),
    enabled: false,
  });

  const {
    data: financeGraphData,
    refetch: getFinanceGraphData,
    isLoading: financeGraphDataLoading,
  } = useQuery({
    queryKey: ["get-finance-graph-data"],
    queryFn: () => GetFinanceGraphData(),
    enabled: false,
  });

  // Combined loading state
  const isLoading =
    getPrescriptionsForFinanceLoading || financeGraphDataLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  const totalPages = getPrescriptionsForFinance?.data?.totalPages ?? 1;

  const headers = [
    { key: "id", label: "ID" },
    { key: "pr_id", label: "Prescription ID" },
    { key: "doctor_name", label: "Doctor Name" },
    { key: "patient_name", label: "Patient Name" },
    { key: "mobile", label: "Patient Mobile No." },
    { key: "bill_number", label: "Bill Number" },
    { key: "total_bil", label: "Total Bill" },
    { key: "discountAmount", label: "Patient Discount" },
    { key: "commissionAmount", label: "Doctor Commission" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Date & Time" },
  ];

  const tableData = getPrescriptionsForFinance?.data?.prescription?.map(
    (t: any) => {
      return {
        id: t?.prescription_id,
        pr_id: t?.pr_id,
        doctor_name: `${t?.User?.firstname} ${t?.User?.lastname}`,
        patient_name: t?.patient_name,
        mobile: t?.mobile,
        bill_number: t?.Bill?.bill_number ?? "None",
        total_bil: t?.Bill?.total_bill ?? "None",
        discountAmount:
          t?.discountAmount != null
            ? currencyFormatter.format(t.discount_amount)
            : "None",
        commissionAmount:
          t?.commissionAmount != null
            ? currencyFormatter.format(t.commission_amount)
            : "None",
        status: t?.status,
        createdAt: moment(t?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      };
    }
  );

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSortBy = (value: string) => {
    setSort(value);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    getPrescriptionsForFinanceData();
    getFinanceGraphData();
  }, []);

  useEffect(() => {
    getPrescriptionsForFinanceData();
  }, [
    currentPage,
    getPrescriptionsForFinanceData,
    debouncedSearchValue,
    filter,
    sort,
  ]);

  const amountData = [
    { id: 1, amount: financeGraphData?.data?.totalClosePayment },
    { id: 2, amount: financeGraphData?.data?.paidToDoctors },
    {
      id: 3,
      amount: financeGraphData?.data?.discountToPatients,
    },
    {
      id: 4,
      amount: financeGraphData?.data?.pendingDues,
    },
  ];

  return (
    <>
      {isAuthorized && (
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 flex flex-col gap-4 h-full">
            <FinancialGraph amountData={amountData} />
            <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex-1 flex flex-col">
              <div className="flex items-center justify-between ps-[18px]">
                <div className="">
                  <h2 className="text-base text-dark-black font-semibold">
                    Financial Data
                  </h2>
                </div>
                <FinanceToolBar
                  handleFilter={handleFilter}
                  handleSearch={handleSearch}
                  handleSortBy={handleSortBy}
                  search={search}
                />
              </div>
              <PrescriptionList
                headers={headers}
                data={tableData}
                currentPage={currentPage}
                totalPages={totalPages}
                selected={[]}
                handleSelected={() => {}}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
      <Authorize />
    </>
  );
};
