import { FC, useEffect, useState } from "react";
import { useDebouncedValue } from "../../hooks";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetPrescriptionsByDeliveryBoy,
  GetPrescriptionStatusCountByDeliveryBoy,
} from "../../utils/query";
import moment from "moment";
import { PrescriptionList } from "../../components/prescriptions";
import { updatePrescriptionStatus } from "../../utils/mutations";
import { PrescriptionHeader } from "../../components/prescriptions/PrescriptionHeader";
import { OrderCountCard } from "../../components/delivery";
import { useLoading } from "../../context/LoadingContext"; // Import useLoading
import { toast } from "react-toastify";

export const BillyRivera: FC = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [debouncedSearchValue] = useDebouncedValue(search, 200);

  const { showLoading, hideLoading } = useLoading();

  // Fetch prescriptions by delivery boy
  const {
    data: getPrescriptionsByDeliveryBoy,
    isLoading: getPrescriptionsByDeliveryBoyLoading,
    refetch: getPrescriptionsByDeliveryBoyData,
  } = useQuery({
    queryKey: ["get-prescriptions-by-delivery-boy"],
    queryFn: () =>
      GetPrescriptionsByDeliveryBoy({
        page: currentPage,
        limit: 10,
        search: debouncedSearchValue,
        status: sort,
        filter,
        deliveryboy_id: Number(id),
      }),
    enabled: false,
  });

  // Fetch prescription status count by delivery boy
  const {
    data: getPrescriptionsByDeliveryBoyCount,
    isLoading: getPrescriptionsByDeliveryBoyCountLoading,
    refetch: getPrescriptionsByDeliveryBoyCountData,
  } = useQuery({
    queryKey: ["get-prescriptions-by-delivery-boy-count"],
    queryFn: () => GetPrescriptionStatusCountByDeliveryBoy(Number(id)),
    enabled: false,
  });

  // Mutation to update prescription status
  const updatePrescriptionStatusMutation = useMutation({
    mutationKey: ["update-prescription-status"],
    mutationFn: updatePrescriptionStatus,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      getPrescriptionsByDeliveryBoyData();
      getPrescriptionsByDeliveryBoyCountData();
      setStatus("");
    },
  });

  // Show or hide loading spinner based on API calls
  useEffect(() => {
    if (
      getPrescriptionsByDeliveryBoyLoading ||
      getPrescriptionsByDeliveryBoyCountLoading
    ) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [
    getPrescriptionsByDeliveryBoyLoading,
    getPrescriptionsByDeliveryBoyCountLoading,
    showLoading,
    hideLoading,
  ]);

  const totalPages = getPrescriptionsByDeliveryBoy?.data?.totalPages ?? 1;

  // Prescription table headers
  const headers = [
    { key: "", label: "" },
    { key: "id", label: "ID" },
    { key: "pr_id", label: "Prescription ID" },
    { key: "bill_number", label: "Bill Number" },
    { key: "patient_name", label: "Patient Name" },
    { key: "mobile", label: "Patient Mobile No." },
    { key: "address", label: "Delivery Address" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Time" },
  ];

  // Format table data
  const tableData = getPrescriptionsByDeliveryBoy?.data?.prescription?.map(
    (t: any) => ({
      id: t?.prescription_id,
      pr_id: t?.pr_id,
      bill_number: t?.Bill?.bill_number ?? "None",
      patient_name: t?.patient_name,
      mobile: t?.mobile,
      address: t?.address ? t?.address : "None",
      status: t?.status,
      createdAt: moment(t?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
    })
  );

  // Page change handler
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Search, sort, and filter handlers
  const handleSearch = (value: string) => setSearch(value);
  const handleSortBy = (value: string) => setSort(value);
  const handleFilter = (value: string) => setFilter(value);

  // Handle checkbox selection
  const handleSelected = (event: any) => {
    const productId = parseInt(event.target.value);
    setSelected((prev) =>
      event.target.checked
        ? [...prev, productId]
        : prev.filter((id) => id !== productId)
    );
  };

  // Handle status update for selected items
  const handleStatus = (value: string) => {
    setStatus(value);
    if (selected?.length && value) {
      updatePrescriptionStatusMutation.mutate({
        ids: selected,
        status: value,
      });
    }
  };

  // Refetch data on component mount and on dependencies change
  useEffect(() => {
    getPrescriptionsByDeliveryBoyData();
    getPrescriptionsByDeliveryBoyCountData();
  }, [currentPage, debouncedSearchValue, filter, sort]);

  // Update selected items based on selectAll flag
  useEffect(() => {
    setSelected(
      selectAll
        ? getPrescriptionsByDeliveryBoy?.data?.prescription?.map(
            (pres: any) => pres.prescription_id
          )
        : []
    );
  }, [getPrescriptionsByDeliveryBoy?.data?.prescription, selectAll]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4 h-full">
        <div className="grid grid-cols-4 gap-4">
          <OrderCountCard
            status="total"
            count={getPrescriptionsByDeliveryBoyCount?.data?.totalPrescriptions}
            title="Total Order"
          />
          <OrderCountCard
            status="dispatch"
            count={
              getPrescriptionsByDeliveryBoyCount?.data?.dispatchPrescriptions
            }
            title="Total Dispatched"
          />
          <OrderCountCard
            status="delivered"
            count={
              getPrescriptionsByDeliveryBoyCount?.data?.deliveredPrescriptions
            }
            title="Total Delivered"
          />
          <OrderCountCard
            status="return"
            count={
              getPrescriptionsByDeliveryBoyCount?.data?.returnPrescriptions
            }
            title="Total Return"
          />
        </div>
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex-1 flex flex-col">
          <PrescriptionHeader
            excludeStatus={[
              "open",
              "preparing",
              "declined",
              "dispatch",
              "closed",
            ]}
            showStatus={!!selected?.length}
            search={search}
            selectedStatus={status}
            handleSearch={handleSearch}
            handleStatus={handleStatus}
            handleSortBy={handleSortBy}
            handleFilter={handleFilter}
            setSelectAll={setSelectAll}
          />
          <PrescriptionList
            headers={headers}
            data={tableData}
            currentPage={currentPage}
            totalPages={totalPages}
            selected={selected}
            handleSelected={handleSelected}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
