import { FC, useEffect, useState } from "react";
import { useDebouncedValue } from "../../hooks";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetDoctorDetails,
  GetPrescriptionsByDoctor,
  GetPrescriptionStatusCountByDoctore,
  GetTotalPaidAndTotalDue,
} from "../../utils/query";
import { statusData } from "../../utils";
import {
  PrescriptionCountCard,
  PrescriptionList,
} from "../../components/prescriptions";
import { useLocation, useParams } from "react-router-dom";
import { DoctorDetailsCard } from "../../components/doctor/DoctorDetailsCard";
import { Finance } from "../../components/doctor";
import moment from "moment";
import { PrescriptionHeader } from "../../components/prescriptions/PrescriptionHeader";
import { updatePrescriptionStatus } from "../../utils/mutations";
import { currencyFormatter } from "../../utils/func";
import { useLoading } from "../../context/LoadingContext";
import { toast } from "react-toastify";
import { MainPages } from "../../Router";
import { is } from "date-fns/locale";

export const DoctorDetails: FC = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [debouncedSearchValue] = useDebouncedValue(search, 200);
  const { showLoading, hideLoading } = useLoading();

  const {
    data: allPrescriptionCountByDoctor,
    isLoading: allPrescriptionCountByDoctorLoading,
    refetch: getAllPrescriptionsCountByDoctore,
  } = useQuery({
    queryKey: ["all-prescription-count-by-doctor"],
    queryFn: () => GetPrescriptionStatusCountByDoctore(Number(id)),
    enabled: false,
  });

  const {
    data: prescriptionsBydoctorData,
    isLoading: prescriptionsBydoctorDataLoading,
    refetch: getPrescriptionsByDoctor,
  } = useQuery({
    queryKey: ["get-prescriptions-by-doctor"],
    queryFn: () =>
      GetPrescriptionsByDoctor({
        page: currentPage,
        limit: 10,
        search: debouncedSearchValue,
        status: sort,
        filter,
        user_id: Number(id),
      }),
    enabled: false,
  });

  const {
    data: getDoctoreDetails,
    isLoading: doctorDetailsLoading,
    refetch: getDoctorDetails,
  } = useQuery({
    queryKey: ["doctor-details"],
    queryFn: () => GetDoctorDetails(Number(id)),
    enabled: false,
  });

  const {
    data: totalPaidAndTotalDue,
    isLoading: totalPaidAndTotalDueLoading,
    refetch: getTotalPaidAndTotalDue,
  } = useQuery({
    queryKey: ["total-paid-and-total-due"],
    queryFn: () => GetTotalPaidAndTotalDue(Number(id)),
    enabled: false,
  });

  const updatePrescriptionStatusMutation = useMutation({
    mutationKey: ["update-prescription-status"],
    mutationFn: updatePrescriptionStatus,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      getPrescriptionsByDoctor();
      getAllPrescriptionsCountByDoctore();
      getDoctorDetails();
      getTotalPaidAndTotalDue();
      setStatus("");
    },
  });

  const totalPages = prescriptionsBydoctorData?.data?.totalPages ?? 1;

  const headers = [
    { key: "", label: "" }, // Checkbox
    { key: "id", label: "ID" },
    { key: "pr_id", label: "Prescription ID" },
    { key: "patient_name", label: "Patient Name" },
    { key: "mobile", label: "Patient Mobile No." },
    { key: "bill_number", label: "Bill Number" },
    { key: "total_bil", label: "Total Bill" },
    // { key: "discountAmount", label: "Patient Discount" },
    { key: "commission_amount", label: "Custom Commission" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Date & Time" },
  ];

  const tableData = prescriptionsBydoctorData?.data?.prescription?.map(
    (t: any) => {
      return {
        id: t?.prescription_id,
        pr_id: t?.pr_id,
        patient_name: t?.patient_name,
        mobile: t?.mobile,
        bill_number: t?.Bill?.bill_number ?? "None",
        total_bil: t?.Bill?.total_bill
          ? currencyFormatter.format(t?.Bill?.total_bill)
          : "None",
        discountAmount:
          t?.discountAmount != null
            ? currencyFormatter.format(t.discountAmount)
            : "None",
        commissionAmount:
          t?.commissionAmount != null
            ? currencyFormatter.format(t.commissionAmount)
            : "None",
        status: t?.status,
        commission_amount: t?.commission_amount
          ? currencyFormatter.format(t.commission_amount)
          : "None",
        createdAt: moment(t?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      };
    }
  );

  const location = useLocation();

  const isFinancial = location.pathname?.includes(MainPages.Financial);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSortBy = (value: string) => {
    setSort(value);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleSelected = (event: any) => {
    const productId = parseInt(event.target.value); // Get the product ID from the value attribute
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelected((prev) => [...prev, productId]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== productId));
    }
  };

  const handleStatus = (value: string) => {
    setStatus(value);
    if (selected?.length && value) {
      updatePrescriptionStatusMutation.mutate({
        ids: selected,
        status: value,
      });
    }
  };

  const handleFetchAll = () => {
    getAllPrescriptionsCountByDoctore();
    getDoctorDetails();
    getPrescriptionsByDoctor();
    getTotalPaidAndTotalDue();
  };

  const isLoading =
    allPrescriptionCountByDoctorLoading ||
    prescriptionsBydoctorDataLoading ||
    doctorDetailsLoading ||
    totalPaidAndTotalDueLoading ||
    updatePrescriptionStatusMutation.isPending;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    handleFetchAll();
  }, []);

  useEffect(() => {
    getPrescriptionsByDoctor();
  }, [
    currentPage,
    getPrescriptionsByDoctor,
    debouncedSearchValue,
    filter,
    sort,
  ]);

  useEffect(() => {
    if (selectAll) {
      setSelected(
        prescriptionsBydoctorData?.data?.prescription?.map(
          (pres: any) => pres.prescription_id
        )
      );
    } else {
      setSelected([]);
    }
  }, [prescriptionsBydoctorData?.data?.prescription, selectAll]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4">
        <div className="grid grid-cols-7 gap-4">
          {allPrescriptionCountByDoctor?.data?.length &&
            allPrescriptionCountByDoctor?.data?.map((d: any, index: number) => (
              <PrescriptionCountCard
                key={index}
                status={d.status}
                title={d.status}
                count={d.count}
              />
            ))}
        </div>
        {/* <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl flex items-center justify-between p-4">
                  <p className="text-base text-primary-black font-medium">
                    Dr. Ronald Richards requested to delete their account{" "}
                  </p>
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className="text-red border border-red text-base font-bold py-2 px-4 rounded-[20px] uppercase"
                    >
                      Decline
                    </button>
                    <button
                      type="button"
                      className="text-white bg-red border border-red text-base font-bold py-2 px-4 rounded-[20px] uppercase"
                    >
                      Accept
                    </button>
                  </div>
                </div> */}
        <div
          className={`grid ${
            isFinancial ? "grid-cols-2" : "grid-cols-1"
          } gap-4`}
        >
          <DoctorDetailsCard doctor={getDoctoreDetails?.data?.doctor} />
          {isFinancial && (
            <Finance
              totalPaidAndTotalDue={totalPaidAndTotalDue?.data}
              doctor={getDoctoreDetails?.data?.doctor}
              handleFetchAll={handleFetchAll}
            />
          )}
        </div>
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4">
          <PrescriptionHeader
            showStatus={!!selected?.length}
            search={search}
            selectedStatus={status}
            handleSearch={handleSearch}
            handleStatus={handleStatus}
            handleSortBy={handleSortBy}
            handleFilter={handleFilter}
            setSelectAll={setSelectAll}
          />
          <PrescriptionList
            hideColumns={isFinancial ? [] : ["commission_amount"]}
            headers={headers}
            data={tableData}
            currentPage={currentPage}
            totalPages={totalPages}
            selected={selected}
            handleSelected={handleSelected}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
